import { withDependencies } from '@wix/thunderbolt-ioc'
import { IInitPagePhases } from './types'
import _ from 'lodash'

export const InitPagePhases = withDependencies<IInitPagePhases>([], () => {
	const UNFINISHED_PHASE = -1
	let phases: { [phase: string]: number } = {}

	return {
		start(phase) {
			const start = Date.now()
			phases[phase] = UNFINISHED_PHASE

			return () => {
				phases[phase] = Date.now() - start
			}
		},
		clear() {
			phases = {}
		},
		getDurations() {
			return phases
		},
		getUnfinished() {
			return _(phases)
				.pickBy((value) => value === UNFINISHED_PHASE)
				.keys()
				.value()
		},
	}
})
